import { Link } from "gatsby"
import React from "react"

import styled from "@emotion/styled"

const Tags = props => (
  <TagsWrapper>
    <Cat>Tag(s):</Cat>

    {props.tags.map(tag => (
      <StyledLink
        to={`/chirurgie-${tag
          .replace(/\s+/g, "-")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")}/`}
      >
        {tag}
      </StyledLink>
    ))}
  </TagsWrapper>
)

export default Tags

const TagsWrapper = styled.div`
  text-align: right;
  font-size: 13px;
  font-weight: 500;
  a {
    margin-right: 5px;
    text-decoration: none;
  }
  svg {
    font-size: 0.8em;
    margin-right: 0px;
  }
`

const Cat = styled.cat`
  margin-right: 5px;
`

const StyledLink = styled(Link)`
  color: rgba(44, 44, 44, 0.96)
  margin-right: 5px;
  text-decoration: none;
  font-weight: 600;
  text-transform: capitalize;
  ::after {
    content: ",";
  }
  :last-of-type {
    ::after {
      content: "";
    }
  }
`
